import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import api from "services/api";
import { AplicationState } from "store";
import { dataBiometriaProps } from "store/modules/dataBiometria/types";
import { backStep } from "store/modules/dataIndexStep/action";

import { protocoloProps } from "utils/types";
import { Toast } from "components/Toast";

import { ButtonBack } from "components/ButtonBack";
import { Container } from "./styles";

export const ConfirmarBiometria: React.FC = () => {
  const dataBiometria = useSelector<AplicationState, dataBiometriaProps>(
    (state) => state.dataBiometria.data
  );

  const protocoloPagamento = JSON.parse(
    sessionStorage.getItem("@CheckoutPagamento:protocoloPagamento") ?? ""
  ) as protocoloProps;

  const formPayment = JSON.parse(
    sessionStorage.getItem("@CheckoutPagamento:formaPagamento") ?? ""
  );

  const messageSucess =
    "Para sua segurança enviaremos um SMS no número de celular e um e-mail para que a verificação de identidade seja realizada. Após a verificação de identidade você poderá prosseguir com o pagamento.";

  const messageLoading =
    "Aguarde um instante enquanto enviamos o a verificação de identidade.";

  const [statusFinal, setStatusFinal] = useState("loading");
  const [textInfo, setTextInfo] = useState(messageLoading);
  const [titleToast, setTitleToast] = useState("Processando biometria");

  const dispatch = useDispatch();

  const enviarDadosBiometria = useCallback(async () => {
    const dadosBiometria = {
      guidProtocolo: protocoloPagamento.GuidProtocolo,
      idFormaPagamento: formPayment.Id,
      chaveEvento: formPayment.ChavePrimeiroEvento,
      clienteTelefone: dataBiometria.clienteTelefone,
      clienteEmail: dataBiometria.clienteEmail,
    };

    await api
      .post("Protocolo/confirmar-evento", dadosBiometria)
      .then(() => {
        setTitleToast("Atenção");
        setTextInfo(messageSucess);
        setStatusFinal("success");
      })
      .catch((error) => {
        const { Messages } = error.response.data;
        setTextInfo(Messages || error.message);
        setStatusFinal("danger");
        setTitleToast("Atenção");
      });
  }, [
    dataBiometria.clienteEmail,
    dataBiometria.clienteTelefone,
    formPayment.ChavePrimeiroEvento,
    formPayment.Id,
    protocoloPagamento.GuidProtocolo,
  ]);

  useEffect(() => {
    enviarDadosBiometria();
  }, [enviarDadosBiometria]);

  return (
    <Container>
      <Toast title={titleToast} textInfo={textInfo} type={statusFinal} />
      {statusFinal === "danger" && (
        <div className="contentButton">
          <ButtonBack title="voltar" action={() => dispatch(backStep())} />
        </div>
      )}
      {statusFinal === "success" && (
        <div className="contentButton">
          <ButtonBack
            title="fechar aba"
            action={() => {
              window.location.href = `${process.env.REACT_APP_URL_PORTAL_HAVAN}`;
            }}
          />
        </div>
      )}
    </Container>
  );
};
