import React from "react";

import { formaPagamentoSelecionadaProps, protocoloProps } from "utils/types";
import { FormStepper } from "components/FormWithSteps/FormStepper";
import { FormStep } from "components/FormWithSteps/FormStep";

import Content from "components/layout/Content";

import { Biometria } from "pages/Pagamento/Biometria";

import { ConfirmarBiometria } from "pages/Pagamento/Biometria/ConfirmarBiometria";
import { DadosCartao } from "./DadosCartao";
import { DadosTitular } from "./DadosTitular";
import { RevisarPagamento } from "./RevisarPagamento";
import { ConfirmarPagamento } from "./ConfirmarPagamento";
import { Pix } from "./Pix";

import { Container, ContentForm } from "./styles";

const Pagamento: React.FC = () => {
  const protocoloPagamento = JSON.parse(
    sessionStorage.getItem("@CheckoutPagamento:protocoloPagamento") ?? ""
  ) as protocoloProps;

  const selectPayment = JSON.parse(
    sessionStorage.getItem("@CheckoutPagamento:formaPagamento") ?? ""
  ) as formaPagamentoSelecionadaProps;

  const biometriaNaoAprovada =
    selectPayment.TipoPagamento === 2 &&
    selectPayment.Bandeira !== "elo" &&
    (protocoloPagamento.FormaPagamentoSelecionada === null ||
      protocoloPagamento.ChaveEventoAtual ===
        "ACESSODIGITAL_CONSULTABIOMETRIADEBITO" ||
      (protocoloPagamento.ChaveEventoAtual === "MUNDIPAGG_AUTORIZACAO" &&
        protocoloPagamento.FormaPagamentoSelecionada.ChavePrimeiroEvento ===
          "MUNDIPAGG_AUTORIZACAO") ||
      protocoloPagamento.ChaveEventoAtual === "PIXHAVAN_CONSULTA_COBRANCA");

  const pagamentoPix = selectPayment.TipoPagamento === 3;
  const pagamentoCartaoHavan = selectPayment.TipoPagamento === 4;

  const progressLabels = [
    "Informe os dados do cartão",
    "Informe os seus dados",
    "As informações estão corretas?",
    "Confirmação de pagamento",
  ];

  const progressLabelsPix = [
    "Informe os seus dados",
    "Confirmação da Biometria",
  ];

  return (
    <Content>
      <Container typePayment={selectPayment.TipoPagamento} className="mb-3">
        <ContentForm>
          {biometriaNaoAprovada ? (
            <FormStepper noForm labels={progressLabelsPix} disabledButtonBack>
              <FormStep>
                <Biometria />
              </FormStep>
              <FormStep>
                <ConfirmarBiometria />
              </FormStep>
            </FormStepper>
          ) : (
            <>
              {pagamentoPix ? (
                <Pix />
              ) : (
                <>
                  {pagamentoCartaoHavan ? (
                    <div>cartão havan</div>
                  ) : (
                    <FormStepper noForm labels={progressLabels} showBorder>
                      <FormStep>
                        <DadosCartao />
                      </FormStep>

                      <FormStep>
                        <DadosTitular />
                      </FormStep>

                      <FormStep>
                        <RevisarPagamento />
                      </FormStep>

                      <FormStep>
                        <ConfirmarPagamento />
                      </FormStep>
                    </FormStepper>
                  )}
                </>
              )}
            </>
          )}
        </ContentForm>
      </Container>
    </Content>
  );
};

export default Pagamento;
