// eslint-disable-next-line no-shadow
export enum dataAddressTypes {
  SET_DATA_BIOMETRIA = "@user/SET_DATA_BIOMETRIA",
}

export interface dataBiometriaProps {
  clienteTelefone: string;
  clienteEmail: string;
}

export interface userDataBiometriaProps {
  data: dataBiometriaProps;
}

export const schemaBiometriaProps = {
  clienteTelefone: "",
  clienteEmail: "",
};
