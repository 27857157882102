import React, { InputHTMLAttributes, useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { Container } from "./styles";

type CheckboxProps = InputHTMLAttributes<HTMLInputElement> & {
  label: string;
  name: string;
  width?: string;
};

export const CheckBox: React.FC<CheckboxProps> = ({ label, name, ...rest }) => {
  const checkboxRef = useRef<HTMLInputElement>(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    if (checkboxRef.current) {
      checkboxRef.current.checked = defaultValue;
    }
    registerField({
      name: fieldName,
      ref: checkboxRef,
      getValue: (ref) => {
        if (ref.current) {
          return ref.current.checked && true;
        }
      },
      setValue: (ref, value) => {
        if (ref.current) {
          ref.current.value = value;
          ref.current.checked = value;
        }
      },
      clearValue: (ref) => {
        if (ref.current) {
          ref.current.value = "";
          ref.current.checked = false;
        }
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <Container>
      <input
        className={`form-check-input ${error ? "is-invalid" : ""}`}
        type="checkbox"
        id={name}
        name={name}
        ref={checkboxRef}
        defaultValue={defaultValue}
        {...rest}
      />
      <label htmlFor={name}> {label} </label>
    </Container>
  );
};
