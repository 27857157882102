import { dataAddressProps, dataAddressTypes } from "./types";

export function setUserAddress(data: dataAddressProps) {
  return {
    type: dataAddressTypes.SET_USER_ADDRESS,
    payload: {
      data,
    },
  };
}
