import { dataIndexProps } from "./types";

export function setIndexStep(data: dataIndexProps) {
  return {
    type: "@step/SET_STEP",
    payload: {
      data,
    },
  };
}

export function nextStep() {
  return {
    type: "@step/NEXT_STEP",
  };
}

export function backStep() {
  return {
    type: "@step/BACK_STEP",
  };
}
