import { ReactNode } from "react";
import { Container, CurrentChild, BarsProgress, ContentLabels } from "./styles";

type ProgressBarProps = {
  children?: ReactNode[];
  currentStep: number;
  labels?: string[];
  inConcluidoStep?: any;
  actionStep: (key: any) => void;
  index: number;
};

export const ProgressBar: React.FC<ProgressBarProps> = ({
  currentStep,
  actionStep,
  labels = [],
  index,
}) => {
  return (
    <Container>
      <ContentLabels index={index} totalLabels={labels}>
        {labels?.map((item, index) => (
          <CurrentChild
            key={index}
            active={currentStep === index}
            onClick={() => {
              actionStep(index);
            }}
          >
            {labels && <p> {labels[index]} </p>}
          </CurrentChild>
        ))}
      </ContentLabels>

      <div className="contentBars">
        {labels?.map((item, index) => (
          <CurrentChild
            key={index}
            active={currentStep === index}
            onClick={() => actionStep(index)}
          >
            <BarsProgress
              index={labels}
              active={currentStep === index}
              current={currentStep === index}
            />
          </CurrentChild>
        ))}
      </div>
    </Container>
  );
};
