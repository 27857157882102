import React, { useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AplicationState } from "store";

import iconeUsuario from "assets/icons/icon-user.svg";
import iconePin from "assets/icons/icon-pin.svg";
import iconeEmail from "assets/icons/icon-mail.svg";
import iconeTelefone from "assets/icons/icon-phone.svg";
import iconeCalendario from "assets/icons/icon-calendario.svg";
import iconeCartao from "assets/icons/icon-card.svg";
import iconeCadeado from "assets/icons/icon-lock.svg";

import { dataPaymentProps } from "store/modules/dataPayment/types";

import { dataAddressProps } from "store/modules/dataAddress/types";

import { useHistory } from "react-router";

import { ModalConfirm } from "components/ModalConfirm";
import { useModal } from "hooks/modal";

import { formaPagamentoSelecionadaProps, protocoloProps } from "utils/types";

import { useEffect } from "react";

import { CheckBox } from "components/Form/CheckBox";
import { FormInput } from "components/Form/Input";
import { ButtonGroup } from "components/Form/ButtonGroup";
import { ButtonBack } from "components/ButtonBack";
import { backStep, nextStep } from "store/modules/dataIndexStep/action";
import { ButtonNext } from "components/ButtonNext";
import { Form } from "@unform/web";
import { FormHandles } from "@unform/core";

import { ToastValues } from "components/ToastValues";
import { Container, ContentForm, TitleSection, ContentRow } from "./styles";

export const RevisarPagamento: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const { showModal, Close } = useModal();

  const dispatch = useDispatch();

  const dataPayment = useSelector<AplicationState, dataPaymentProps>(
    (state) => state.dataPayment.data
  );

  const dataAddress = useSelector<AplicationState, dataAddressProps>(
    (state) => state.dataAddress.data
  );

  const protocoloPagamento = JSON.parse(
    sessionStorage.getItem("@CheckoutPagamento:protocoloPagamento") ?? ""
  ) as protocoloProps;

  const selectPayment = JSON.parse(
    sessionStorage.getItem("@CheckoutPagamento:formaPagamento") ?? ""
  ) as formaPagamentoSelecionadaProps;

  const history = useHistory();

  const handleSubmit = useCallback(() => {
    dispatch(nextStep());
  }, [dispatch]);

  useEffect(() => {
    formRef.current?.setFieldValue("souTitular", dataAddress.souTitular);
    if (dataPayment.formaPagamento === 0) {
      showModal({
        title: "Atenção",
        content: (
          <ModalConfirm
            textInfo="Sessão expirada, tente novamente."
            confirm={() => {
              history.push(`/?guid=${protocoloPagamento.GuidProtocolo}`);
              Close();
            }}
          />
        ),
      });
    }
  }, [
    Close,
    dataAddress,
    dataPayment,
    history,
    protocoloPagamento.GuidProtocolo,
    showModal,
  ]);

  return (
    <Container>
      <ContentForm>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <div className="mb-4">
            <ToastValues payment={selectPayment} />
          </div>

          <TitleSection>Verifique os dados do cartão</TitleSection>

          <FormInput
            icon={iconeCartao}
            type="text"
            label="numeroCartao"
            disabled
            value={dataPayment.numeroCartao}
          />

          <FormInput
            icon={iconeUsuario}
            type="text"
            label="nomeCartao"
            disabled
            value={dataPayment.nomeCartao}
          />

          <ContentRow>
            <div className="col">
              <FormInput
                icon={iconeCalendario}
                label="validade"
                disabled
                defaultValue={dataPayment.validade}
              />
            </div>

            <div className="col">
              <FormInput
                icon={iconeCadeado}
                disabled
                label="cvv"
                type="text"
                defaultValue={dataPayment.cvv}
              />
            </div>
          </ContentRow>

          <TitleSection>Verifique os dados do Cliente</TitleSection>

          <FormInput
            icon={iconeUsuario}
            type="text"
            label="nomecliente"
            disabled
            value={protocoloPagamento.ClienteNome}
          />

          <FormInput
            icon={iconeTelefone}
            mask="(99) 99999-9999"
            placeholder="Telefone"
            label="clienteTelefone"
            disabled
            defaultValue={dataAddress.clienteTelefone}
          />

          <FormInput
            icon={iconeEmail}
            placeholder="E-mail"
            label="clienteEmail"
            disabled
            defaultValue={dataAddress.clienteEmail}
          />

          <div className="mt-4 mb-3">
            <CheckBox
              label="Sou Titular do cartão"
              name="souTitular"
              disabled
            />
          </div>

          <TitleSection>Verifique os dados do titular do cartão</TitleSection>

          {dataAddress.souTitular === false && (
            <div className="mb-2">
              <div className="row g-2">
                <div className="col">
                  <FormInput
                    icon={iconeUsuario}
                    placeholder="CPF ou CNPJ do titular do cartão"
                    label="documentoTitularCartao"
                    defaultValue={dataAddress.documentoTitularCartao}
                    maxLength={18}
                    disabled
                  />
                </div>
                <div className="col-md">
                  <FormInput
                    mask="(99) 99999 9999"
                    icon={iconeTelefone}
                    placeholder="Telefone do titular do cartão"
                    label="telefoneTitularCartao"
                    defaultValue={dataAddress.telefoneTitularCartao}
                    disabled
                  />
                </div>
              </div>

              <FormInput
                icon={iconeEmail}
                placeholder="E-mail do titular do cartão"
                label="emailTitularCartao"
                defaultValue={dataAddress.emailTitularCartao}
                disabled
              />
            </div>
          )}

          <div className="mb-3">
            {/* <div className="mb-3">
              <TitleSection>Endereço do titular do cartão</TitleSection>
            </div> */}

            <ContentRow>
              <div className="col">
                <FormInput
                  mask="99999-999"
                  placeholder="CEP"
                  label="cep"
                  disabled
                  defaultValue={dataAddress.cep}
                />
              </div>

              <div className="col">
                <FormInput
                  disabled
                  placeholder="Cidade"
                  label="cidade"
                  type="text"
                  defaultValue={`${dataAddress.cidade} - ${dataAddress.uf}`}
                />
              </div>
            </ContentRow>

            <FormInput
              icon={iconePin}
              placeholder="Endereço"
              label="logradouro"
              type="text"
              disabled
              defaultValue={dataAddress.logradouro}
            />

            <ContentRow>
              <div className="col">
                <FormInput
                  placeholder="Número"
                  label="numero"
                  disabled
                  type="number"
                  defaultValue={dataAddress.numero}
                />
              </div>
              <div className="col">
                <FormInput
                  placeholder="Bairro"
                  label="bairro"
                  disabled
                  type="text"
                  defaultValue={dataAddress.bairro}
                />
              </div>
            </ContentRow>

            <FormInput
              placeholder="Complemento"
              label="complemento"
              disabled
              type="text"
              defaultValue={dataAddress.complemento}
            />
          </div>

          <ButtonGroup>
            <ButtonBack
              title="VOLTAR"
              type="button"
              action={() => {
                dispatch(backStep());
              }}
            />

            <ButtonNext title="confirmar" type="submit" />
          </ButtonGroup>
        </Form>
      </ContentForm>
    </Container>
  );
};
