import React, { createContext, useCallback, useContext, useState } from "react";

import api from "services/api";

interface AuthContextData {
  token: any;
  setToken(token: string): void;
}

interface AuthState {
  token: string;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
  const [data, setData] = useState<AuthState>(() => {
    const token = sessionStorage.getItem("@CheckoutPagamento:token");

    if (token) {
      api.defaults.headers.authorization = `Bearer ${token}`;

      return {
        token,
      };
    }

    return {} as AuthState;
  });

  const setToken = useCallback((token: string) => {
    sessionStorage.setItem("@CheckoutPagamento:token", token);

    api.defaults.headers.authorization = `Bearer ${token}`;

    setData({ token });
  }, []);

  return (
    <AuthContext.Provider value={{ token: data, setToken }}>
      {children}
    </AuthContext.Provider>
  );
};

function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }

  return context;
}

export { AuthProvider, useAuth };
