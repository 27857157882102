import { Reducer } from "redux";
import produce from "immer";

import { userDataIndexProps } from "./types";

const INITIAL_STATE: userDataIndexProps = {
  data: { step: 0 },
};

const indexStep: Reducer<userDataIndexProps> = (
  state = INITIAL_STATE,
  action
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case "@step/NEXT_STEP": {
        draft.data.step = state.data.step + 1;
        break;
      }
      case "@step/BACK_STEP": {
        draft.data.step = state.data.step - 1;
        break;
      }
      case "@step/SET_STEP": {
        draft.data = action.payload.data;
        break;
      }
      default:
    }
  });
};

export default indexStep;
