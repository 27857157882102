import React, { createContext, useContext, useState } from "react";
import { Spinner } from "../components/Spinner";

type SpinnerContextData = {
  loading: boolean;
  setLoading(b: boolean): void;
};

const SpinnerContext = createContext<SpinnerContextData>(
  {} as SpinnerContextData
);

const SpinnerProvider: React.FC = ({ children }) => {
  const [loading, setLoading] = useState(false);

  return (
    <SpinnerContext.Provider value={{ loading, setLoading }}>
      <Spinner show={loading} />
      {children}
    </SpinnerContext.Provider>
  );
};

function useSpinner(): SpinnerContextData {
  const context = useContext(SpinnerContext);

  if (!context) {
    throw new Error("useSpinner must be used within an SpinnerProvider");
  }

  return context;
}

export { SpinnerProvider, useSpinner };
