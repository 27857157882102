import React from "react";

import secondaryLogoHavan from "assets/icons/logo-havan.svg";

import { Container } from "./styles";

const Header: React.FC = () => {
  return (
    <Container data-cy="header">
      <img src={secondaryLogoHavan} alt="logoHavan" />
      <p>Checkout</p>
    </Container>
  );
};

export default Header;
