import React, { ButtonHTMLAttributes } from "react";

import { Button } from "./styles";

interface buttonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  title: string;
  action(): any;
  icon?: string;
}

export const ButtonBack: React.FC<buttonProps> = ({ title, icon, action }) => {
  return (
    <Button type="button" onClick={action}>
      {icon && <img src={icon} alt="iconButton" />}
      <p>{title}</p>
    </Button>
  );
};
