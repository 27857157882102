import styled, { css } from "styled-components";
import colors from "styles/colors";

type ButtonProps = {
  outline?: boolean;
  disabled?: boolean;
  hasChildren?: boolean;
  variant: "primary" | "success" | "danger" | "warning" | "info";
  buttonSmall?: boolean;
};

export const Container = styled.button<ButtonProps>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem 0.5rem;
  border: 0;
  border-radius: 5px;

  color: #f7f7f7;

  width: ${(props) => (props.buttonSmall ? "auto" : "auto")}px;

  ${(props) =>
    !props.buttonSmall &&
    css`
      @media screen and (max-width: 1060px) {
        width: 100%;
      }
    `}

  ${(props) =>
    props.disabled &&
    css`
      cursor: auto;

      filter: brightness(110%);
    `}

  ${(props) =>
    props.variant === "primary" &&
    css`
      background-color: #002a5d;
      height: 50px;
      padding: 0 20px;
      width: 100%;
    `}

  ${(props) =>
    props.variant === "success" &&
    css`
      background-color: #478c5c;
    `}

  ${(props) =>
    props.variant === "danger" &&
    css`
      background-color: #aa1945;
      height: 50px;
      padding: 0 20px;
    `}

  ${(props) =>
    props.variant === "warning" &&
    css`
      background-color: #b95c50;
      height: 50px;
      padding: 0 20px;
    `}

  ${(props) =>
    props.variant === "info" &&
    css`
      background-color: #c3e0e5;
      color: #0c2d48;
    `}

  ${(props) =>
    props.outline &&
    css`
      background-color: transparent;
      border: 1px solid ${colors.primary};
    `}

  ${(props) =>
    props.hasChildren &&
    css`
      svg {
        margin-right: 0.2rem;
      }
    `}

  :hover {
    ${(props) =>
      !props.disabled &&
      css`
        filter: brightness(80%);
      `}
  }
`;
