import { Reducer } from "redux";
import produce from "immer";

import { userDataAddressProps, dataAddressTypes } from "./types";

const INITIAL_STATE: userDataAddressProps = {
  data: {
    clienteTelefone: "",
    clienteEmail: "",
    souTitular: false,
    documentoTitularCartao: "",
    telefoneTitularCartao: "",
    emailTitularCartao: "",
    cep: "",
    cidade: "",
    logradouro: "",
    numero: "",
    bairro: "",
    complemento: "",
    uf: "",
  },
};

const dataPayment: Reducer<userDataAddressProps> = (
  state = INITIAL_STATE,
  action
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case dataAddressTypes.SET_USER_ADDRESS: {
        draft.data = action.payload.data;

        break;
      }
      default:
    }
  });
};

export default dataPayment;
