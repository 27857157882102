import styled from "styled-components";

export const DivRow = styled.div``;

export const Container = styled.div`
  justify-content: center;
  max-width: 580px;
  padding: 0 20px;
  width: 100vw;

  @media screen and (max-width: 550px) {
    width: 100%;
  }

  form {
    width: 100%;
  }
`;

export const TitleSection = styled.p`
  color: #06357a;
  font-size: 16px;
  font-weight: 700;
  margin: 32px 0 8px;
`;
