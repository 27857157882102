import styled, { css } from "styled-components";

interface toastProps {
  type: string;
}

export const Container = styled.div<toastProps>`
  border-radius: 8px;
  color: #ffffff;
  padding: 20px;
  max-width: 400px;

  transition: all 0.2s;

  #textTitle {
    font-weight: bold;
    font-size: 17px;
    @media screen and (max-width: 530px) {
      font-size: 16px;
    }
  }

  #textInfo {
    font-weight: 300;
    margin-top: 8px;
    font-size: 15px;
    text-align: justify;
    @media screen and (max-width: 530px) {
      font-size: 13px;
    }
  }

  ${(props) =>
    props.type === "success" &&
    css`
      background-color: #0cad0f;
    `}

  ${(props) =>
    props.type === "danger" &&
    css`
      background-color: #bc2f2f;
    `}

      ${(props) =>
    props.type === "loading" &&
    css`
      background-color: #076bee;
    `}
`;
