import { dataPaymentProps } from "./types";

export function setUserPay(data: dataPaymentProps) {
  return {
    type: "@user/SET_USER_PAY",
    payload: {
      data,
    },
  };
}
