import { shade } from "polished";
import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const Content = styled.div`
  margin-bottom: 20px;
  font-size: 1.1rem;
  text-align: center;
`;

export const ButtonConfirm = styled.button`
  background: ${(props) => (!props.disabled ? "#076bee" : "#a0b4d1")};
  border-radius: 8px;
  width: 100%;
  height: 48px;
  border: 0;

  @media screen and (max-width: 700px) {
    width: 100%;
  }

  display: flex;
  justify-content: center;
  align-items: center;

  transition: all 0.5s;

  ${(props) =>
    !props.disabled &&
    css`
      :hover {
        background-color: ${shade(0.1, "#076BEE")};
      }
    `}

  color: #fff;
  font-weight: bold;
  font-size: 14px;
  line-height: 14px;

  p {
    margin-left: 8px;
    text-transform: uppercase;
  }
`;
