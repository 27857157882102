import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;
  width: 100%;
  height: Calc(100vh - 400px);
  transition: all 0.2s;

  @media screen and (max-width: 540px) {
    height: Calc(100vh - 200px);
  }

  .contentButton {
    width: 100%;
    margin-top: 30px;
  }
`;
