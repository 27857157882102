import Button from "components/Button";

import { ButtonGroup } from "components/Form/ButtonGroup";
import { useModal } from "../../hooks/modal";
import { Container, Content, ButtonConfirm } from "./styles";

type ConfirmProps = {
  confirm?: () => void;
  cancelButton?: boolean;
  textInfo: string;
  textButtonConfirm?: string;
};

export const ModalConfirm: React.FC<ConfirmProps> = ({
  confirm,
  cancelButton,
  textInfo,
  textButtonConfirm,
}) => {
  const { Close } = useModal();

  return (
    <Container>
      <Content>{textInfo}</Content>
      <ButtonGroup>
        {cancelButton && (
          <Button onClick={Close} data-bs-dismiss="modal">
            Cancelar
          </Button>
        )}

        {confirm ? (
          <ButtonConfirm
            data-bs-dismiss="modal"
            data-bs-target="#modalHAVAN"
            onClick={confirm}
          >
            <p>{textButtonConfirm || "confirmar"}</p>
          </ButtonConfirm>
        ) : (
          <ButtonConfirm data-bs-dismiss="modal" data-bs-target="#modalHAVAN">
            <p>{textButtonConfirm || "confirmar"}</p>
          </ButtonConfirm>
        )}
      </ButtonGroup>
    </Container>
  );
};
