import { Reducer } from "redux";
import produce from "immer";

import { userDataPaymentProps, dataPaymentTypes } from "./types";

const INITIAL_STATE: userDataPaymentProps = {
  data: {
    numeroCartao: "",
    mesVencimento: "",
    anoVencimento: "",
    validade: "",
    nomeCartao: "",
    cvv: "",
    formaPagamento: 0,
    bandeira: "",
  },
};

const dataPayment: Reducer<userDataPaymentProps> = (
  state = INITIAL_STATE,
  action
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case dataPaymentTypes.SET_USER: {
        draft.data = action.payload.data;

        break;
      }
      default:
    }
  });
};

export default dataPayment;
