import { Reducer } from "redux";
import produce from "immer";

import { userDataBiometriaProps, dataAddressTypes } from "./types";

const INITIAL_STATE: userDataBiometriaProps = {
  data: {
    clienteTelefone: "",
    clienteEmail: "",
  },
};

const dataPayment: Reducer<userDataBiometriaProps> = (
  state = INITIAL_STATE,
  action
) => {
  return produce(state, (draft) => {
    switch (action.type) {
      case dataAddressTypes.SET_DATA_BIOMETRIA: {
        draft.data = action.payload.data;

        break;
      }
      default:
    }
  });
};

export default dataPayment;
