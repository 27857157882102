import styled from "styled-components";

export const Container = styled.div`
  color: #fff;
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  background: linear-gradient(97.44deg, #1e99f7 0%, #0c3bdd 100%);
  border-radius: 8px;
  padding: 0 20px;

  transition: all 0.2s;

  @media screen and (max-width: 530px) {
    display: none;
  }

  img {
    width: 120px;
  }

  p {
    font-size: 1.1rem;
    font-weight: bold;
  }
`;
