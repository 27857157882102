import React, { useCallback, useEffect, useState } from "react";
import Content from "components/layout/Content";

import pixIcon from "assets/icons/icon-pix.svg";
import caixaIcon from "assets/icons/logo-caixa.svg";
import c6bankIcon from "assets/icons/logo-c6.svg";
import creditCard from "assets/icons/icon-card.svg";
import logohavan from "assets/icons/logo-havan-b.svg";
import iconhavan from "assets/icons/logo-hvn.svg";
import api from "services/api";
import { useAuth } from "hooks/auth";
import { useToast } from "hooks/toast";
import { useSpinner } from "hooks/spinner";

import { useHistory, useLocation } from "react-router";

import { PageNotFound } from "pages/NotFound";
import { ModalConfirm } from "components/ModalConfirm";
import { useModal } from "hooks/modal";
import { TiposSituacoes } from "utils/enum";
import { protocoloProps } from "utils/types";
import { useDispatch } from "react-redux";
import { setUserPay } from "store/modules/dataPayment/action";
import { setUserAddress } from "store/modules/dataAddress/action";
import { schemaPaymentProps } from "store/modules/dataPayment/types";
import { schemaAddressProps } from "store/modules/dataAddress/types";
import { setIndexStep } from "store/modules/dataIndexStep/action";
import { Container, TitleSection, Button, ContentLogo } from "./styles";
import { typesPayment } from "./types";

const FormasPagamento: React.FC = () => {
  const [formPayment, setFormPayment] = useState<typesPayment[]>();

  const [protocoloPagamento, setProcolocoPagamento] =
    useState<protocoloProps>();

  const dispatch = useDispatch();

  const { setToken } = useAuth();
  const { addToast } = useToast();
  const { loading, setLoading } = useSpinner();

  const history = useHistory();
  const { showModal } = useModal();

  const queryURL = new URLSearchParams(useLocation().search);
  const guid = queryURL.get("guid") ?? "";

  const getIcon = useCallback((tipoPagamento: number, bandeira: string) => {
    let iconSelect = "";

    if (tipoPagamento === 1) {
      iconSelect = creditCard;
    }

    if (tipoPagamento === 2 && bandeira.length > 0) {
      iconSelect = caixaIcon;
    }

    if (tipoPagamento === 2 && bandeira.length === 0) {
      iconSelect = c6bankIcon;
    }

    if (tipoPagamento === 3) {
      iconSelect = pixIcon;
    }

    if (tipoPagamento === 4) {
      iconSelect = iconhavan;
    }

    return iconSelect;
  }, []);

  const getTitleButton = useCallback(
    (tipoPagamento: number, bandeira: string) => {
      let textButton = "";

      if (tipoPagamento === 1) {
        textButton = "Cartão de Crédito";
      }

      if (tipoPagamento === 2 && bandeira.length > 0) {
        textButton = "Cartão de Débito Virtual Caixa";
      }

      if (tipoPagamento === 2 && bandeira.length === 0) {
        textButton = "Cartão de Débito C6 Bank";
      }

      if (tipoPagamento === 3) {
        textButton = "Pix";
      }

      if (tipoPagamento === 4) {
        textButton = "Cartão Havan";
      }

      return textButton;
    },
    []
  );

  const formartFormPayment = useCallback((formasPagamento) => {
    if (formasPagamento) {
      const filtrarPix = formasPagamento.filter(
        (item: typesPayment) => item.Nome === "Pix"
      );

      const filtrarOutrosMetodos = formasPagamento
        .filter(
          (item: typesPayment) =>
            item.Nome !== "Pix" && item.Nome !== "Cartão Havan"
        )
        .sort((a: typesPayment, b: typesPayment) => (a.Nome < b.Nome ? -1 : 1));

      const novoArrayOrdenado = [...filtrarPix, ...filtrarOutrosMetodos];

      return novoArrayOrdenado;
    }

    return [];
  }, []);

  const getDataGuid = useCallback(
    async (newGuid) => {
      setLoading(true);

      sessionStorage.removeItem("@CheckoutPagamento:token");
      sessionStorage.removeItem("@CheckoutPagamento:protocoloPagamento");

      await api
        .get(`Protocolo?guid=${newGuid}`)
        .then((result) => {
          const { Data, Messages } = result.data;
          setLoading(false);

          if (Messages[0].includes("Tempo limite excedido")) {
            showModal({
              title: "Atenção",
              content: (
                <ModalConfirm textInfo={Messages[0]} textButtonConfirm="ok" />
              ),
            });

            return;
          }

          if (Data.Situacao === TiposSituacoes.concluido) {
            showModal({
              title: "Atenção",
              content: (
                <ModalConfirm
                  textInfo="Seu pagamento já foi recebido, você será redirecionado."
                  confirm={() => {
                    window.location.href = `${Data.UrlRedirecionamentoSucesso}`;
                  }}
                />
              ),
            });

            return;
          }

          if (Data.ChaveEventoAtual === "CLEARSALE_ENVIAANTIFRAUDE") {
            showModal({
              title: "Atenção",
              content: (
                <ModalConfirm
                  textInfo="Seu pagamento está em processamento aguarde, você será redirecionado."
                  confirm={() => {
                    window.location.href = `${Data.UrlRedirecionamentoSucesso}`;
                  }}
                />
              ),
            });

            return;
          }

          if (
            Data.Situacao === TiposSituacoes.cancelado ||
            Data.Situacao === TiposSituacoes.divergencia
          ) {
            showModal({
              title: "Atenção",
              content: (
                <ModalConfirm
                  textInfo={
                    Data.Situacao === TiposSituacoes.cancelado
                      ? "Esse processo de pagamento não está mais disponível."
                      : "Esse processo de pagamento encontra-se com divergências e não é possível prosseguir."
                  }
                  confirm={() => {
                    window.location.href = `${Data.UrlRedirecionamentoErro}`;
                  }}
                />
              ),
            });

            return;
          }

          const primeiroAcesso =
            sessionStorage.getItem("@CheckoutPagamento:primeiroAcesso") ?? "";

          const dataUserPayment = {
            Id: Data.Id,
            ClienteNome: Data.ClienteNome,
            GuidProtocolo: newGuid,
            ChaveEventoAtual: Data.ChaveEventoAtual,
            ValorLiquido: Data.Valor,
            ValorParcela: 1,
            Parcelas: Data.Parcelas,
            FormasPagamento: Data.FormasPagamento,
            FormaPagamentoSelecionada: Data.FormaPagamentoSelecionada,
            PermiteTrocaFormaPagamento: Data.PermiteTrocaFormaPagamento,
            UrlRedirecionamentoErro: Data.UrlRedirecionamentoErro,
            UrlRedirecionamentoSucesso: Data.UrlRedirecionamentoSucesso,
            Situacao: Data.Situacao,
          };

          setToken(Data.Token);
          setFormPayment(formartFormPayment(Data.FormasPagamento));

          sessionStorage.setItem(
            "@CheckoutPagamento:protocoloPagamento",
            JSON.stringify(dataUserPayment)
          );

          if (
            Data.FormaPagamentoSelecionada &&
            Data.FormaPagamentoSelecionada.TipoPagamento === 2 &&
            Data.FormaPagamentoSelecionada.Bandeira === "" &&
            Data.ChaveEventoAtual === "MUNDIPAGG_AUTORIZACAO" &&
            !primeiroAcesso
          ) {
            sessionStorage.setItem(
              "@CheckoutPagamento:formaPagamento",
              JSON.stringify(Data.FormaPagamentoSelecionada)
            );

            sessionStorage.setItem(
              "@CheckoutPagamento:primeiroAcesso",
              "false"
            );

            history.push("/pagamento");
          }
        })
        .catch(() => {
          setLoading(false);
        });
    },
    [formartFormPayment, history, setLoading, setToken, showModal]
  );

  const verifyFormPaymentSelected = useCallback(
    (formPaymentSelected: typesPayment) => {
      if (
        protocoloPagamento &&
        protocoloPagamento.FormaPagamentoSelecionada !== null &&
        !protocoloPagamento.PermiteTrocaFormaPagamento &&
        formPaymentSelected.Id !==
          protocoloPagamento.FormaPagamentoSelecionada.Id
      ) {
        addToast({
          type: "danger",
          title: "Atenção",
          description:
            "Não é possível alterar a forma de pagamento selecionada",
        });
      } else {
        sessionStorage.setItem(
          "@CheckoutPagamento:formaPagamento",
          JSON.stringify(formPaymentSelected)
        );
        history.push("/pagamento");
      }
    },
    [addToast, history, protocoloPagamento]
  );

  const resetarPagamento = useCallback(() => {
    dispatch(setUserPay(schemaPaymentProps));
    dispatch(setUserAddress(schemaAddressProps));
    dispatch(setIndexStep({ step: 0 }));
  }, [dispatch]);

  useEffect(() => {
    resetarPagamento();

    if (guid) {
      getDataGuid(guid);
      history.push("/");
      return;
    }

    const buscarProtocolo =
      sessionStorage.getItem("@CheckoutPagamento:protocoloPagamento") ?? "";

    if (buscarProtocolo !== "") {
      const protocolo = JSON.parse(buscarProtocolo);
      setProcolocoPagamento(protocolo);
      getDataGuid(protocolo.GuidProtocolo);
      history.push("/");
    }
  }, [getDataGuid, guid, history, resetarPagamento]);

  return (
    <Content>
      {formPayment ? (
        <>
          <Container>
            <TitleSection>Qual é a forma de pagamento?</TitleSection>
            {formPayment?.map((item, index) => (
              <Button
                name={item.Nome}
                data-cy={item.Id}
                type="button"
                onClick={() => {
                  verifyFormPaymentSelected(item);
                }}
                key={index}
              >
                <div>
                  <div id="contentIcon">
                    <img
                      src={getIcon(item.TipoPagamento, item.Bandeira)}
                      alt="iconPayment"
                    />
                  </div>

                  <div
                    className="d-flex justify-content-between"
                    id="contentInfo"
                  >
                    <p>{getTitleButton(item.TipoPagamento, item.Bandeira)}</p>
                    {item.Nome === "Pix" && <div id="contentNovo">NOVO</div>}
                  </div>
                </div>
              </Button>
            ))}
          </Container>
          <ContentLogo id="contentLogo">
            <img src={logohavan} alt="logoHavan" />
          </ContentLogo>
        </>
      ) : (
        <>{!loading && <PageNotFound />}</>
      )}
    </Content>
  );
};

export default FormasPagamento;
