import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { FormHandles } from "@unform/core";

import creditCardType from "lib/credit-card-type";
import { FormInput } from "components/Form/Input";
import { AplicationState } from "store";
import { dataPaymentProps } from "store/modules/dataPayment/types";

import getLogoPayment from "utils/getLogoPayment";
import { formaPagamentoSelecionadaProps } from "utils/types";

import iconeCalendario from "assets/icons/icon-calendario.svg";
import iconeCartao from "assets/icons/icon-card.svg";
import iconeUsuario from "assets/icons/icon-user.svg";
import iconeCadeado from "assets/icons/icon-lock.svg";

import formValidator from "pages/Pagamento/DadosCartao/formValidator";

import { ButtonBack } from "components/ButtonBack";
import { ButtonNext } from "components/ButtonNext";
import { Form } from "@unform/web";

import { setUserPay } from "store/modules/dataPayment/action";

import { getValidationsErros } from "utils/getValidationsErros";
import { useToast } from "hooks/toast";

import { useHistory } from "react-router";
import { nextStep } from "store/modules/dataIndexStep/action";

import { ButtonGroup } from "components/Form/ButtonGroup";
import { ToastValues } from "components/ToastValues";
import Cartao from "./Cartao";

import {
  Container,
  ContentLeft,
  ContentRight,
  DivRowContent,
  ContentMobile,
  ContentWeb,
  DivRow,
} from "./styles";

interface CardProps {
  mesVencimento: string;
  anoVencimento: string;
  nomeCartao: string;
  cvv: string;
  bandeira: string;
  numeroCartao: string;
  validade: string;
}

const schemaCardProps = {
  mesVencimento: "",
  anoVencimento: "",
  nomeCartao: "",
  cvv: "",
  bandeira: "",
  numeroCartao: "",
  validade: "",
};

export const DadosCartao: React.FC = () => {
  const formRef = useRef<FormHandles>(null);

  const [dataCard, setDataCard] = useState<CardProps>(schemaCardProps);

  const [numeroCartao, setNumeroCartao] = useState("");
  const [tamanhoCvv, setTamanhoCvv] = useState(4);

  const dataPayment = useSelector<AplicationState, dataPaymentProps>(
    (state) => state.dataPayment.data
  );

  const selectPayment = JSON.parse(
    sessionStorage.getItem("@CheckoutPagamento:formaPagamento") ?? ""
  ) as formaPagamentoSelecionadaProps;

  const dispatch = useDispatch();
  const { addToast } = useToast();
  const history = useHistory();

  const getCardType = useCallback(
    (number: string) => {
      if (number.length < 1) {
        setDataCard({
          ...dataCard,
          bandeira: "",
        });
        setTamanhoCvv(4);

        return;
      }

      const formart = number.replaceAll(" ", "");

      const type = creditCardType(formart);

      if (type) {
        const filterType = type.filter((item, index) => index === 0);

        if (filterType.length > 0) {
          setDataCard({
            ...dataCard,
            bandeira: filterType[0].niceType,
          });

          setTamanhoCvv(filterType[0].code.size);
        } else if (number.length > 16 && !filterType[0]) {
          formRef.current?.setFieldError("numeroCartao", "Cartão inválido.");
        } else {
          setDataCard({
            ...dataCard,
            bandeira: "",
          });
        }
      }
    },
    [dataCard]
  );

  const salvarDadosCartao = useCallback(
    async (data: CardProps) => {
      try {
        formRef.current?.setErrors({});
        await formValidator?.validate(data, {
          abortEarly: false,
        });

        const actualYear = new Date().getFullYear();

        const formatData = data.validade.split("/");

        const dadosCartao = {
          numeroCartao: data.numeroCartao,
          anoVencimento: formatData[1],
          mesVencimento: formatData[0],
          nomeCartao: data.nomeCartao,
          cvv: data.cvv,
          formaPagamento: selectPayment.TipoPagamento,
          bandeira: dataCard.bandeira,
          validade: data.validade,
        };

        if (
          selectPayment.Bandeira.toUpperCase() === "ELO" &&
          dadosCartao.bandeira?.toUpperCase() !== "ELO"
        ) {
          addToast({
            type: "danger",
            title: "Atenção",
            description:
              "Bandeira não aceita para pagamentos Caixa Econômica Federal",
          });

          formRef.current?.setFieldError("numeroCartao", "* Cartão inválido");

          return;
        }

        if (dadosCartao.mesVencimento && dadosCartao.anoVencimento) {
          if (
            actualYear > Number(dadosCartao.anoVencimento) ||
            dadosCartao.mesVencimento > "12"
          ) {
            addToast({
              type: "danger",
              title: "Atenção",
              description:
                "Seu cartão parece estar vencido, verifique a data de vencimento.",
            });

            formRef.current?.setFieldError("validade", "Cartão vencido");

            return;
          }
        }

        if (Number(dadosCartao.anoVencimento) > actualYear + 10) {
          addToast({
            type: "danger",
            title: "Atenção",
            description: "Atenção, verifique a data de vencimento.",
          });

          formRef.current?.setFieldError("validade", "Data inválida");

          return;
        }

        if (!dadosCartao?.bandeira) {
          addToast({
            type: "danger",
            title: "Atenção",
            description: "Cartão inválido.",
          });

          formRef.current?.setFieldError("numeroCartao", "* Cartão inválido");

          return;
        }

        dispatch(setUserPay(dadosCartao));
        dispatch(nextStep());
      } catch (err: any) {
        const errors = getValidationsErros(err);
        formRef.current?.setErrors(errors);
      }
    },
    [
      addToast,
      dataCard.bandeira,
      dispatch,
      selectPayment.Bandeira,
      selectPayment.TipoPagamento,
    ]
  );

  const numberCard = formRef.current?.getFieldValue("numeroCartao");
  const nameCard = formRef.current?.getFieldValue("nomeCartao");
  const validateCard = formRef.current?.getFieldValue("validade");
  const cvvCard = formRef.current?.getFieldValue("cvv");

  const verificarDados = useMemo(
    () => !(numberCard && nameCard && validateCard && cvvCard),
    [cvvCard, nameCard, numberCard, validateCard]
  );

  useEffect(() => {
    formRef.current?.setFieldValue("numeroCartao", dataPayment.numeroCartao);
    formRef.current?.setFieldValue("nomeCartao", dataPayment.nomeCartao);
    formRef.current?.setFieldValue("validade", dataPayment.validade);
    formRef.current?.setFieldValue("cvv", dataPayment.cvv);
  }, [
    dataPayment.cvv,
    dataPayment.nomeCartao,
    dataPayment.numeroCartao,
    dataPayment.validade,
  ]);

  useEffect(() => {
    if (dataPayment.formaPagamento !== 0) {
      setDataCard(dataPayment);
      setNumeroCartao(dataPayment.numeroCartao);
    }
  }, [dataPayment]);

  return (
    <Container typePayment={selectPayment.TipoPagamento}>
      <DivRowContent>
        <ContentLeft>
          <div className="mt-1 mb-3" id="contentTitlePayment">
            <p id="titleMethodPayment">Informe os dados do cartão</p>
          </div>
          <Form ref={formRef} onSubmit={(e) => salvarDadosCartao(e)}>
            <FormInput
              width="input-group input-group-lg"
              mask="9999 9999 9999 9999"
              number
              icon={iconeCartao}
              placeholder="Número do cartão"
              label="numeroCartao"
              onChange={(e) => {
                getCardType(e.target.value);
                setNumeroCartao(e.target.value);
              }}
              defaultValue={dataCard.numeroCartao}
            >
              {dataCard?.bandeira ? (
                <img
                  src={getLogoPayment(dataCard?.bandeira)}
                  alt="iconCartao"
                />
              ) : (
                "*"
              )}
            </FormInput>

            <FormInput
              width="input-group input-group-lg"
              icon={iconeUsuario}
              label="nomeCartao"
              className="uppercaseInput"
              autoComplete="none"
              type="text"
              placeholder="Nome impresso no cartão"
              defaultValue={dataCard.nomeCartao}
              onChange={(value) =>
                setDataCard({
                  ...dataCard,
                  nomeCartao: value.target.value,
                })
              }
            >
              *
            </FormInput>
            <DivRow className="d-flex justify-content-between">
              <div>
                <FormInput
                  icon={iconeCalendario}
                  placeholder="Validade"
                  label="validade"
                  mask="99/9999"
                  onChange={(value) =>
                    setDataCard({
                      ...dataCard,
                      validade: value.target.value,
                    })
                  }
                  value={dataCard.validade}
                >
                  *
                </FormInput>
              </div>
              <div>
                <FormInput
                  width="input-group input-group-lg"
                  maxLength={tamanhoCvv}
                  icon={iconeCadeado}
                  type="text"
                  mask="9999"
                  label="cvv"
                  placeholder="CVV"
                  defaultValue={dataPayment.cvv}
                  onChange={(value) => {
                    setDataCard({
                      ...dataCard,
                      cvv: value.target.value,
                    });
                  }}
                >
                  *
                </FormInput>
              </div>
            </DivRow>

            <ContentMobile>
              <ToastValues payment={selectPayment} />
            </ContentMobile>

            <div className="mt-4">
              <ButtonGroup>
                <ButtonBack title="VOLTAR" action={() => history.goBack()} />

                <ButtonNext
                  title="PRÓXIMO"
                  type="submit"
                  disabled={verificarDados}
                />
              </ButtonGroup>
            </div>
          </Form>
        </ContentLeft>

        <ContentRight>
          <Cartao
            numeroCartao={numeroCartao}
            nomeCartao={dataCard.nomeCartao}
            validade={dataCard.validade}
            bandeira={dataCard.bandeira}
            cvv={dataCard.cvv}
          />

          <ContentWeb>
            <ToastValues payment={selectPayment} />
          </ContentWeb>
        </ContentRight>
      </DivRowContent>
    </Container>
  );
};
