import React from "react";
import Header from "components/layout/Header";

import { Container, ContentDiv } from "./styles";

interface ContentProps {
  title?: string;
  buttonBack?: boolean;
}

const Content: React.FC<ContentProps> = ({ children }) => {
  return (
    <Container>
      <Header />
      <ContentDiv>{children}</ContentDiv>
    </Container>
  );
};

export default Content;
