import { shade } from "polished";
import styled from "styled-components";

interface tipoPagamento {
  name: string;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 80px);

  @media screen and (min-width: 530px) {
    height: auto;
  }

  #contentNovo {
    background: #076bee;
    width: 46px;
    height: 24px;
    color: #eee;
    padding: 5px;
    border-radius: 3px;

    font-weight: 500;
    font-size: 10px;
    line-height: 16px;

    justify-content: center;
    display: flex;

    @media screen and (max-width: 400px) {
      font-size: 14px;
      width: 50px;
    }
  }

  #contentInfo {
    margin-left: 10px;
    width: 100%;
    margin-right: 0px;
    font-family: "Nunito";
    font-weight: 700;
    font-size: 16px;
  }
`;

export const Button = styled.button<tipoPagamento>`
  height: 56px;
  width: 376px;

  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.15);
  box-sizing: border-box;
  border-radius: 8px;
  background-color: #fff;
  font-size: 20px;
  padding: 0 20px;

  border: ${(props) => (props.name === "Pix" ? "1px solid #184fa1" : 0)};

  :hover {
    background-color: ${shade(0.05, "#fff")};
  }

  @media screen and (max-width: 400px) {
    font-size: 15px;
    font-weight: bold;
    padding: 0 10px;
    width: 320px;
  }

  div {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 25px;
      height: 25px;
    }
  }

  & + button {
    margin-top: 10px;
  }
`;

export const TitleSection = styled.p`
  font-weight: bold;
  font-size: 24px;
  font-family: "Nunito";
  color: #06357a;
  margin-bottom: 5px;
  text-align: center;
  margin: 20px 0 24px;
  width: 100%;
  max-width: 600px;
  transition: all 0.2s;

  @media screen and (max-width: 1060px) {
    font-size: 18px;
  }
`;

export const ContentLogo = styled.div`
  display: none;
  justify-content: center;

  @media screen and (max-width: 530px) {
    display: flex;
  }
`;
