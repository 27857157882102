import { object, string } from "yup";

export default object().shape({
  clienteTelefone: string().min(15, "Mínimo 11 digítos"),
  cep: string().min(9, "Mínimo 8 digítos"),
  documentoTitularCartao: string().required("Campo obrigatório"),
  telefoneTitularCartao: string().min(15, "Mínimo 11 digítos"),
  emailTitularCartao: string()
    .required("Campo obrigatório")
    .email("Digite um e-mail válido"),
  logradouro: string().required("Campo obrigatório"),
  numero: string().required("Campo obrigatório"),
  bairro: string().required("Campo obrigatório"),

  clienteEmail: string()
    .email("Digite um e-mail válido")
    .required("Campo obrigatório"),
});
