import styled from "styled-components";

export const Container = styled.div`
  input {
    :focus {
      box-shadow: none;
    }
  }

  label {
    margin-left: 0.8rem;
    color: #06357a;
    font-size: 13px;
  }
`;
