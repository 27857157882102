import { ButtonBack } from "components/ButtonBack";
import { ButtonGroup } from "components/Form/ButtonGroup";
import { Toast } from "components/Toast";
import { useModal } from "hooks/modal";
import { useSpinner } from "hooks/spinner";
import { useToast } from "hooks/toast";
import { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router";
import api from "services/api";
import { nextStep } from "store/modules/dataIndexStep/action";
import { TiposSituacoes } from "utils/enum";
import { formaPagamentoSelecionadaProps, protocoloProps } from "utils/types";

import { Container, ContentImage, ContentInfo, ContentErro } from "./styles";

interface dataPixProps {
  Emv: string;
  GuidProtocolo: string;
  ImagemBase64: string;
  Txid: string;
}

const schemaDataPix = {
  Emv: "",
  GuidProtocolo: "",
  ImagemBase64: "",
  Txid: "",
};

export const QrCodePix: React.FC = () => {
  const [dataPix, setDataPix] = useState<dataPixProps>();
  const [verificarSituacao, setVerificarSituacao] = useState<any>();
  const [erroProtocolo, setErroProtocolo] = useState(false);

  const protocoloPagamento = JSON.parse(
    sessionStorage.getItem("@CheckoutPagamento:protocoloPagamento") ?? ""
  ) as protocoloProps;

  const selectPayment = JSON.parse(
    sessionStorage.getItem("@CheckoutPagamento:formaPagamento") ?? ""
  ) as formaPagamentoSelecionadaProps;

  const { loading, setLoading } = useSpinner();
  const { showModal } = useModal();

  const { addToast } = useToast();

  const dispatch = useDispatch();

  const history = useHistory();

  const pathPagamento = history.location.pathname;

  const resetarPagamento = useCallback(() => {
    window.stop();
    clearTimeout(verificarSituacao);
    setVerificarSituacao({});
    history.goBack();
  }, [history, verificarSituacao]);

  const handleSituationGuid = useCallback(async () => {
    if (pathPagamento !== "/pagamento") {
      clearTimeout(verificarSituacao);
      setVerificarSituacao({});
      return;
    }

    await api
      .get(`Protocolo/${protocoloPagamento.GuidProtocolo}/situacao`)
      .then((result) => {
        const { Data } = result.data;

        // setSituaaoPagamento(Data.SituacaoProtocolo);

        if (Data.SituacaoProtocolo === TiposSituacoes.concluido) {
          clearTimeout(verificarSituacao);
          dispatch(nextStep());
          setVerificarSituacao({});
        }

        if (
          Data.SituacaoProtocolo === TiposSituacoes.iniciado ||
          Data.SituacaoProtocolo === TiposSituacoes.emAndamento
        ) {
          setVerificarSituacao(
            setTimeout(() => {
              handleSituationGuid();
            }, 10000)
          );
        }
      })
      .catch((error: Error) => {
        if (error.message === "Request aborted") {
          return;
        }
        resetarPagamento();
        addToast({
          type: "danger",
          title: "Atenção",
          description: error.message,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addToast, protocoloPagamento.GuidProtocolo, showModal, dispatch]);

  const handleGetDataPix = useCallback(async () => {
    setLoading(true);
    setDataPix(schemaDataPix);
    clearTimeout(verificarSituacao);
    setVerificarSituacao({});
    await api
      .get(
        `/Protocolo/${protocoloPagamento.GuidProtocolo}/pix/${selectPayment.Id}`
      )
      .then((result) => {
        const { Data } = result.data;
        setDataPix(Data);
        handleSituationGuid();
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        window.stop();
        clearTimeout(verificarSituacao);
        setVerificarSituacao({});
        setErroProtocolo(true);
        addToast({
          type: "danger",
          title: "Atenção",
          description: error.message,
        });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    setLoading,
    protocoloPagamento.GuidProtocolo,
    selectPayment.Id,
    handleSituationGuid,
    addToast,
  ]);

  useEffect(() => {
    clearTimeout(verificarSituacao);
    setVerificarSituacao({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    handleGetDataPix();
  }, [handleGetDataPix]);

  return (
    <Container>
      <div>
        {dataPix?.ImagemBase64 ? (
          <>
            <ContentImage>
              <ContentInfo>
                <p id="title">Pix Havan</p>
                <p id="info">Escaneie e pague via pix</p>
              </ContentInfo>
              <div id="mainContent">
                <div id="contentQrCode">
                  <img
                    src={`data:image/png;base64,${dataPix.ImagemBase64}`}
                    alt="qrCode"
                  />
                </div>
                <div className="d-flex justify-content-center mb-3">
                  <button
                    type="button"
                    onClick={() => {
                      addToast({
                        type: "info",
                        title: "Atenção",
                        description: "Chave copiada com sucesso",
                      });
                      navigator.clipboard.writeText(dataPix.Emv);
                    }}
                  >
                    <b>COPIAR CHAVE PIX</b>
                  </button>
                </div>
                <div id="contentInfo">
                  <b className="mb-2">Atenção</b>
                  <p>
                    A chave pix gerada acima tem validade de 15 (quinze) minutos
                    para efetivação do pagamento, não sendo possível realizar
                    agendamento de pagamento para data futura.
                  </p>
                </div>
              </div>
            </ContentImage>
            <ButtonGroup>
              <ButtonBack title="VOLTAR" action={resetarPagamento} />
            </ButtonGroup>
          </>
        ) : (
          <>
            {!loading && erroProtocolo && (
              <ContentErro>
                <div>
                  <Toast
                    title="Atenção"
                    textInfo="Falha ao tentar gerar Qrcode, clique no botão abaixo para gerar um novo Qrcode."
                    type="danger"
                  />
                  <ButtonBack title="gerar qrcode" action={handleGetDataPix} />
                </div>
              </ContentErro>
            )}
          </>
        )}
      </div>
    </Container>
  );
};
