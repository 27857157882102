import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  right: 0;
  top: 0;
  margin-top: 30px;
  overflow: hidden;
  z-index: 1000;
`;
