import styled, { css } from "styled-components";

type ProgressProps = {
  active: boolean;
  current?: boolean;
  index?: any;
};

interface contentLabelsProps {
  index: number;
  totalLabels: string[];
}

export const Container = styled.div`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 5px;

  margin-bottom: 40px;

  .contentBars {
    width: 100%;
    max-width: 375px;
    display: flex;
    margin-top: 8px;
  }
`;

export const ContentLabels = styled.div<contentLabelsProps>`
  display: flex;
  justify-content: center;
  transition: all 0.2s;
  overflow-x: hidden;

  ${(props) =>
    props.totalLabels.length > 3 &&
    css`
      @media screen and (min-width: 1150px) {
        ${props.index === 0 &&
        css`
          margin-left: 355px;
        `}

        ${props.index === 1 &&
        css`
          margin-left: 190px;
        `}

      ${props.index === 2 &&
        css`
          margin-left: 100px;
        `}

      ${props.index === 3 &&
        css`
          margin-right: 355px;
        `}
      }
    `}
`;

export const CurrentChild = styled.button<ProgressProps>`
  background-color: transparent;
  border: 0;
  padding: 0 5px;
  width: 100%;

  p {
    font-weight: bold;
    font-size: ${(props) => (props.active ? 16 : 13)}px;
    color: ${(props) => (props.active ? "#076bee" : "#A0B4D1")};
    white-space: nowrap;

    @media screen and (max-width: 770px) {
      display: ${(props) => (props.active ? "block" : "none")};
    }
  }
`;

export const BarsProgress = styled.div<ProgressProps>`
  background: ${(props) => (props.active ? "#076bee" : "#A0B4D1")};
  border-radius: 8px;
  height: 4px;
`;
