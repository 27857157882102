import { dataBiometriaProps, dataAddressTypes } from "./types";

export function setDataBiometria(data: dataBiometriaProps) {
  return {
    type: dataAddressTypes.SET_DATA_BIOMETRIA,
    payload: {
      data,
    },
  };
}
