import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_URL_API_CHECKOUT,
});

const signOut = () => {
  sessionStorage.removeItem("@CheckoutPagamento:token");
};

api.interceptors.response.use(
  (response) => {
    if (response.request.response.includes("Acesso não autorizado")) {
      signOut();
    }

    return response;
  },
  (error: Error) => {
    if (error.message === "Network Error") {
      signOut();
    }
    return Promise.reject(error);
  }
);

export default api;
