import Button from "components/Button";
import { shade } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  padding: 0 24px;
  display: flex;
  justify-content: center;

  div {
    max-width: 350px;
  }
`;

export const ContentImage = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;

  #mainContent {
    align-items: center;
    display: flex;
    flex-direction: column;
  }

  #contentInfo {
    background-color: #f3f8ff;
    width: 300px;
    padding: 1rem;
    border-radius: 8px;
    text-align: justify;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }

  #contentQrCode {
    max-height: 200px;
    max-width: 200px;
    padding: 10px;
    background: #f3f8ff;
    display: flex;
    align-items: center;
    justify-content: center;

    margin: 40px 0;

    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
  }

  button:first-child {
    color: #fff;
    background-color: #0c3bdd;
    border: 0;
    width: 175px;
    height: 48px;

    border-radius: 6px;
    transition: all 0.2s;

    font-size: 14px;

    :hover {
      background-color: ${shade(0.2, "#0C3BDD")};
      color: #fff;
    }
  }
`;

export const ContentInfo = styled.div`
  display: flex;

  flex-direction: column;
  align-items: center;
  text-align: center;

  color: #06357a;

  #title {
    font-size: 24px;
    font-weight: bold;
    line-height: 38px;
  }

  #info {
    font-size: 14px;
  }
`;

export const ButtonSubmit = styled(Button)`
  width: 327px;
  height: 48px;
  background: #f3f8ff;
  border-radius: 8px;
  display: none;
  color: #000;

  @media screen and (max-width: 400px) {
    display: block;
  }

  svg {
    margin: 0 5px;
  }
`;

export const ContentErro = styled.div`
  width: 100%;
  height: 100vh;
  max-height: 400px;

  display: flex;
  flex-direction: column;

  align-items: center;
  justify-content: center;

  div {
    button {
      margin-top: 20px;
    }
  }
`;
