/* eslint-disable react/jsx-props-no-spreading */
import { ButtonHTMLAttributes, ReactNode } from "react";
import { IconBaseProps } from "react-icons/lib";

import { Container } from "./styles";

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> & {
  children?: ReactNode;
  outline?: boolean;
  disabled?: boolean;
  variant?: "primary" | "success" | "danger" | "warning" | "info";
  icon?: React.ComponentType<IconBaseProps>;
  buttonSmall?: boolean;
};

const Button: React.FC<ButtonProps> = ({
  children,
  outline = false,
  disabled = false,
  variant = "primary",
  icon: Icon,
  buttonSmall,
  ...rest
}) => {
  return (
    <Container
      buttonSmall={buttonSmall}
      variant={variant}
      outline={outline}
      hasChildren={!!children}
      disabled={disabled}
      {...rest}
    >
      {Icon && <Icon fontSize="1rem" color="#333" />} {children}
    </Container>
  );
};

export default Button;
