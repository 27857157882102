import React, { ReactNode, useMemo, useState, useCallback } from "react";
import { Form } from "@unform/web";
import * as yup from "yup";

import { ObjectShape } from "yup/lib/object";
import { FormHandles, SubmitHandler } from "@unform/core";

import { ButtonGroup } from "components/Form/ButtonGroup";
import { useEffect } from "react";
import { ButtonBack } from "components/ButtonBack";
import { ButtonNext } from "components/ButtonNext";

// import iconLeftArrow from "assets/icons/icon-left-arrow.svg";

import { useDispatch, useSelector } from "react-redux";
import { AplicationState } from "store";
import { dataIndexProps } from "store/modules/dataIndexStep/types";
import { setIndexStep } from "store/modules/dataIndexStep/action";
import { Container, FormContent, BorderContent } from "./styles";
import { getValidationsErros } from "../../../utils/getValidationsErros";
import { ProgressBar } from "./ProgressBar";
import { FormStepProps } from "../FormStep";
import { useForm } from "../../../hooks/form";

type FormStepperProps = {
  children: ReactNode;
  stepConcluido?: ReactNode;
  yupSchemas?: yup.ObjectSchema<ObjectShape>[];
  buttonBack?: boolean;
  buttonNext?: boolean;
  labels?: string[];
  newFormRef?: React.RefObject<FormHandles>;
  idConcluido?: string;
  actionBack?: () => any;
  // nextStep?: boolean;
  disabledButtonBack?: boolean;
  loading?: boolean;
  showBorder?: boolean;
  noForm?: boolean;
};

export const FormStepper: React.FC<FormStepperProps> = ({
  children,
  yupSchemas = [],
  labels = [],
  newFormRef,
  buttonBack,
  buttonNext,
  stepConcluido,
  idConcluido,
  actionBack,
  // nextStep,
  disabledButtonBack,
  loading,
  showBorder,
  noForm,
}) => {
  const childrenArray = React.Children.toArray(
    children
  ) as React.ReactElement<FormStepProps>[];

  const [step, setStep] = useState(idConcluido ? childrenArray.length : 0);

  const { setFormData, data, formRef } = useForm();
  const currentChild = childrenArray[step] as React.ReactElement<FormStepProps>;

  const dataIndex = useSelector<AplicationState, dataIndexProps>(
    (state) => state.dataIndexStep.data
  );

  const dispatch = useDispatch();

  const inConcluidoStep = useMemo(
    () => step > childrenArray.length || idConcluido,
    [step, childrenArray, idConcluido]
  );

  const isLastStep = useMemo(
    () => step > childrenArray.length - 2,
    [childrenArray, step]
  );

  const currentRef = useMemo(() => {
    return newFormRef ?? formRef;
  }, [formRef, newFormRef]);

  const handleActionBack = useCallback(() => {
    if (step > 0 && !inConcluidoStep) {
      setStep((currentStep) => currentStep - 1);
    } else if (actionBack) {
      actionBack();
    }
  }, [actionBack, inConcluidoStep, step]);

  const handleSubmit: SubmitHandler = useCallback(
    (data) => {
      if (currentChild.props.handleSubmit) {
        currentChild.props.handleSubmit(data).then(() => {
          setStep((currentStep) => currentStep + 1);
        });
      } else {
        setFormData(data);
        setStep((currentStep) => currentStep + 1);
      }
    },
    [currentChild.props, setFormData]
  );

  const handleStepNavigation = useCallback(
    (index: number) => {
      if (step > index && !inConcluidoStep) {
        dispatch(setIndexStep({ step: index }));
      }
    },
    [step, inConcluidoStep, dispatch]
  );

  const handleSchema = useMemo(() => {
    return yupSchemas[step];
  }, [yupSchemas, step]);

  useEffect(() => {
    setStep(dataIndex.step);
  }, [dataIndex]);

  return (
    <Container>
      <ProgressBar
        index={step}
        inConcluidoStep={inConcluidoStep}
        currentStep={step}
        labels={labels}
        actionStep={(index) => {
          if (!disabledButtonBack) {
            handleStepNavigation(index);
          }
        }}
      />
      <BorderContent showBorder={showBorder}>
        {noForm ? (
          <FormContent>
            {inConcluidoStep ? stepConcluido : currentChild}
          </FormContent>
        ) : (
          <Form
            initialData={data}
            ref={currentRef}
            onSubmit={async (values, helpers) => {
              setFormData(values);

              try {
                currentRef.current?.setErrors({});
                await handleSchema?.validate(values, {
                  abortEarly: false,
                });
                handleSubmit(values, helpers);
              } catch (err: any) {
                if (handleSchema) {
                  const errors = getValidationsErros(err);
                  currentRef.current?.setErrors(errors);
                }
              }
            }}
          >
            <FormContent>
              {inConcluidoStep ? stepConcluido : currentChild}
            </FormContent>
            {!disabledButtonBack && !loading && (
              <ButtonGroup>
                {buttonBack && (
                  <ButtonBack title="VOLTAR" action={handleActionBack} />
                )}

                {!inConcluidoStep && buttonNext && (
                  <ButtonNext title={isLastStep ? "Finalizar" : "Próximo"} />
                )}
              </ButtonGroup>
            )}
          </Form>
        )}
      </BorderContent>
    </Container>
  );
};
