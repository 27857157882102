import styled from "styled-components";

interface contentProps {
  align?: "flex-start" | "center" | "flex-end";
}

export const Container = styled.div<contentProps>`
  display: flex;
  justify-content: ${(props) => props.align};
  width: 100%;
  gap: 8px;
`;
