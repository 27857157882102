import styled from "styled-components";

export const Container = styled.div`
  padding: 24px;

  @media screen and (max-width: 530px) {
    padding: 10px;
  }
`;

export const ContentDiv = styled.div`
  flex: 1;
  align-items: center;
  display: flex;
  flex-direction: column;
`;
