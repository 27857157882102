import { combineReducers } from "redux";

import dataPayment from "./dataPayment";
import dataAddress from "./dataAddress";
import dataIndexStep from "./dataIndexStep";
import dataBiometria from "./dataBiometria";

export default combineReducers({
  dataPayment,
  dataAddress,
  dataIndexStep,
  dataBiometria,
});
