import styled from "styled-components";

import colors from "styles/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  width: 580px;

  @media screen and (max-width: 770px) {
    width: 100%;
  }
`;

export const TitleSection = styled.p`
  font-weight: bold;
  font-size: 16px;
  color: #06357a;
  margin: 32px 0 8px;
`;

export const ContentForm = styled.div`
  width: 100%;

  #titleInfo {
    font-size: 13px;
    opacity: 0.8;
    margin-bottom: 4px;
    color: #333;
  }

  #titleValue {
    color: ${colors.primary};
  }

  #divColum {
    @media screen and (max-width: 550px) {
      flex-direction: column;
      align-items: flex-start;
    }

    #noVisibleWeb {
      display: none;
      @media screen and (max-width: 550px) {
        display: flex;
      }
    }
  }
`;

export const ContentRow = styled.div`
  display: flex;
  gap: 8px;
  width: 100%;

  @media screen and (max-width: 530px) {
    gap: 0;
    flex-direction: column;
  }
`;
