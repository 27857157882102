import React, { useCallback, useState } from "react";

import iconUser from "assets/icons/icon-users.svg";

import { BiChevronDownCircle, BiChevronUpCircle } from "react-icons/bi";

import { Container } from "./styles";

interface infoProps {
  textoAlternativo?: string;
}

export const ContentInfo: React.FC<infoProps> = ({ textoAlternativo }) => {
  const [ocultarInfoStts, setOcultarInfoStts] = useState("");

  const ocultarInfo = useCallback(() => {
    const verificar =
      sessionStorage.getItem("@CheckoutPagamento:ocultarInfo") ?? "true";

    if (verificar === "false") {
      sessionStorage.setItem("@CheckoutPagamento:ocultarInfo", "true");
    } else {
      sessionStorage.setItem("@CheckoutPagamento:ocultarInfo", "false");
    }

    setOcultarInfoStts(verificar);
  }, []);

  return (
    <Container>
      <div className="d-flex">
        <img src={iconUser} alt="iconUsers" className="me-2" />

        <p>
          <b>Verificação de identidade</b>
        </p>

        <button
          className="ms-auto"
          type="button"
          onClick={() => {
            ocultarInfo();
          }}
        >
          {ocultarInfoStts === "true" ? (
            <BiChevronUpCircle />
          ) : (
            <BiChevronDownCircle />
          )}
        </button>
      </div>

      {ocultarInfoStts === "true" && (
        <p>
          {textoAlternativo ||
            "Os dados abaixo são coletados para que possamos fazer uma verificação de identidade, trazendo mais segurança ao processo de pagamento"}
        </p>
      )}
    </Container>
  );
};
