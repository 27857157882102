import styled from "styled-components";

interface containerProps {
  typePayment: number;
}

export const Container = styled.div<containerProps>`
  background-color: #fff;
  max-width: ${(props) => (props.typePayment === 0 ? "600" : "1100")}px;
  width: 100%;
`;

export const ContentForm = styled.div`
  max-width: 1200px;
  width: 100%;
`;
