import { ButtonBack } from "components/ButtonBack";
import { Toast } from "components/Toast";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import api from "services/api";
import { AplicationState } from "store";
import { dataAddressProps } from "store/modules/dataAddress/types";
import { backStep } from "store/modules/dataIndexStep/action";
import { dataPaymentProps } from "store/modules/dataPayment/types";
import { TiposBancos } from "utils/enum";
import { formaPagamentoSelecionadaProps, protocoloProps } from "utils/types";

import { Container } from "./styles";

export const ConfirmarPagamento: React.FC = () => {
  const messageSucessCredit =
    "Para sua segurança enviaremos um SMS no número de celular e um e-mail para que a verificação de identidade seja realizada. Após a verificação de identidade você poderá prosseguir com o pagamento.";

  const messageSucessDebito =
    "Pagamento da fatura realizado com sucesso, você será redirecionado para o portal do cliente.";

  const messageLoading =
    "Aguarde um instante enquanto verificamos os dados do seu pagamento.";

  const [statusFinal, setStatusFinal] = useState("loading");
  const [textInfo, setTextInfo] = useState(messageLoading);
  const [titleToast, setTitleToast] = useState("Processando pagamento");

  const dispatch = useDispatch();
  const history = useHistory();

  const dataPayment = useSelector<AplicationState, dataPaymentProps>(
    (state) => state.dataPayment.data
  );

  const dataAddress = useSelector<AplicationState, dataAddressProps>(
    (state) => state.dataAddress.data
  );

  const protocoloPagamento = JSON.parse(
    sessionStorage.getItem("@CheckoutPagamento:protocoloPagamento") ?? ""
  ) as protocoloProps;

  const selectPayment = JSON.parse(
    sessionStorage.getItem("@CheckoutPagamento:formaPagamento") ?? ""
  ) as formaPagamentoSelecionadaProps;

  const sendPayment = useCallback(
    async (payment) => {
      await api
        .post("Protocolo/confirmar-evento", payment)
        .then(() => {
          setTitleToast("Sucesso");
          if (selectPayment.TipoPagamento === 1) {
            setTextInfo(messageSucessCredit);
          } else {
            setTextInfo(messageSucessDebito);
          }

          setStatusFinal("success");
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            history.push(`/?guid=${protocoloPagamento.GuidProtocolo}`);

            return;
          }

          const { Messages } = error.response.data;

          setTextInfo(Messages || "Erro desconhecido, tente novamente.");
          setStatusFinal("danger");
          setTitleToast("Atenção");
        });
    },
    [history, protocoloPagamento.GuidProtocolo, selectPayment.TipoPagamento]
  );

  useEffect(() => {
    if (dataAddress.souTitular) {
      const newPayment = {
        guidProtocolo: protocoloPagamento.GuidProtocolo,
        idFormaPagamento: selectPayment.Id,
        chaveEvento:
          selectPayment.TipoPagamento === 2 && selectPayment.Bandeira === ""
            ? protocoloPagamento.ChaveEventoAtual
            : selectPayment.ChavePrimeiroEvento,
        transacoes: [
          {
            nomeTitularCartao: dataPayment.nomeCartao,
            souTitular: true,
            numeroCartao: dataPayment.numeroCartao,
            mesVencimento: dataPayment.mesVencimento,
            anoVencimento: dataPayment.anoVencimento,
            emailTitularCartao:
              dataAddress.emailTitularCartao || dataAddress.clienteEmail,
            telefoneTitularCartao:
              dataAddress.telefoneTitularCartao || dataAddress.clienteTelefone,
            bandeira: dataPayment.bandeira,
            cvv: dataPayment.cvv,
            valorLiquido: protocoloPagamento.ValorLiquido,
            valorParcela: protocoloPagamento.ValorParcela,
            parcelas: protocoloPagamento.Parcelas.ConfigParcelas[0].Vezes,
            enderecoCobranca: {
              cep: dataAddress.cep,
              logradouro: dataAddress.logradouro,
              numero: dataAddress.numero,
              bairro: dataAddress.bairro,
              cidade: dataAddress.cidade,
              uf: dataAddress.uf,

              complemento: dataAddress.complemento,
            },
          },
        ],
        clienteTelefone: dataAddress.clienteTelefone,
        clienteEmail: dataAddress.clienteEmail,
      };

      sendPayment(newPayment);
    } else {
      const newPayment = {
        guidProtocolo: protocoloPagamento.GuidProtocolo,
        idFormaPagamento: selectPayment.Id,
        chaveEvento:
          selectPayment.Id !== TiposBancos.c6Bank
            ? selectPayment.ChavePrimeiroEvento
            : protocoloPagamento.ChaveEventoAtual,
        transacoes: [
          {
            nomeTitularCartao: dataPayment.nomeCartao,
            documentoTitularCartao: dataAddress.documentoTitularCartao
              ?.replaceAll(".", "")
              .replace("/", "")
              .replace("-", ""),
            souTitular: false,
            numeroCartao: dataPayment.numeroCartao,
            mesVencimento: dataPayment.mesVencimento,
            anoVencimento: dataPayment.anoVencimento,
            bandeira: dataPayment.bandeira,
            cvv: dataPayment.cvv,
            valorLiquido: protocoloPagamento.ValorLiquido,
            valorParcela: protocoloPagamento.ValorParcela,
            parcelas: protocoloPagamento.Parcelas.ConfigParcelas[0].Vezes,
            emailTitularCartao:
              dataAddress.emailTitularCartao || dataAddress.clienteEmail,
            telefoneTitularCartao: dataAddress.telefoneTitularCartao,
            enderecoCobranca: {
              cep: dataAddress.cep,
              logradouro: dataAddress.logradouro,
              numero: dataAddress.numero,
              bairro: dataAddress.bairro,
              cidade: dataAddress.cidade,
              uf: dataAddress.uf,

              complemento: dataAddress.complemento,
            },
          },
        ],
        clienteTelefone: dataAddress.clienteTelefone,
        clienteEmail: dataAddress.clienteEmail,
      };

      sendPayment(newPayment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <Toast title={titleToast} textInfo={textInfo} type={statusFinal} />
      {statusFinal === "danger" && (
        <div className="contentButton">
          <ButtonBack title="voltar" action={() => dispatch(backStep())} />
        </div>
      )}

      {statusFinal === "success" && (
        <div className="contentButton">
          <ButtonBack
            title="fechar aba"
            action={() => {
              window.location.href = `${process.env.REACT_APP_URL_PORTAL_HAVAN}`;
            }}
          />
        </div>
      )}
    </Container>
  );
};
