import { Container } from "./styles";

type SpinnerProps = {
  show?: boolean;
};

export const Spinner: React.FC<SpinnerProps> = ({ show = false }) => {
  if (!show) return null;
  return (
    <Container>
      <div className="spinner-grow text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </Container>
  );
};
