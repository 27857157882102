import { shade } from "polished";
import styled, { css } from "styled-components";
import colors from "styles/colors";

interface ContentProps {
  isFocused: boolean;
  isFilled: boolean;
  disabled?: boolean;
}

interface contentErrorProps {
  error?: string;
}

export const Container = styled.div<ContentProps>`
  display: flex;
  border: 1px solid #556f97;
  height: 48px;

  border-radius: 8px;
  align-items: center;
  padding: 0 15px;

  .logo {
    width: auto;

    img {
      width: 60px;
    }
  }

  #contentInput {
    width: 100%;
    height: 100%;

    input {
      display: flex;

      border: 0;
      height: 100%;
      color: ${colors.dark};

      :focus {
        box-shadow: none;
      }

      :disabled {
        background-color: #f3f8ff;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
  }

  ${(props) =>
    props.disabled &&
    css`
      background-color: #f3f8ff;
      border: 1px solid transparent;
    `}

  ${(props) =>
    props.isFocused &&
    css`
      border: 1px solid #0c3bdd;
    `}

  ${(props) =>
    props.isFilled &&
    css`
      border: 1px solid ${shade(0.2, "red")};
    `}

  svg {
    width: 22px;
    height: 22px;
    color: ${colors.dark};
    opacity: 0.6;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }
`;

export const Error = styled.div<contentErrorProps>`
  margin-bottom: 8px;
  width: 100%;
  display: flex;
  justify-content: flex-end;

  ${(props) =>
    props.error &&
    css`
      margin-bottom: 0;
    `}

  font-size: 12px;
  span {
    color: #c53030;
  }
`;
