import { shade } from "polished";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: auto;
  margin-top: 5rem;
  justify-content: center;
  align-items: center;

  p {
    font-weight: 800;
    font-size: 24px;
    line-height: 32px;
    color: #06357a;
    text-align: center;
    transition: all 0.5s;

    @media screen and (max-width: 530px) {
      font-size: 18px;
    }
  }

  img {
    height: 500px;
    width: 303px;
    margin-bottom: 30px;
    transition: all 0.5s;

    @media screen and (max-width: 530px) {
      height: 400px;
      object-fit: contain;
    }
  }

  button {
    height: 50px;
    width: 303px;
    border: 0;
    background-color: #f3f8ff;
    border-radius: 8px;
    transition: all 0.5s;
    text-transform: uppercase;
    margin-top: 30px;

    :hover {
      background-color: ${shade(0.1, "#f3f8ff")};
    }

    color: #076bee;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
  }
`;
