import { object, string } from "yup";

export default object().shape({
  clienteTelefone: string().min(15, "Mínimo 11 caracteres"),
  cep: string().min(8, "Mínimo 8 caracteres"),
  logradouro: string().required("Campo obrigatório"),
  numero: string().required("Campo obrigatório"),
  bairro: string().required("Campo obrigatório"),
  clienteEmail: string()
    .email("Digite um e-mail válido")
    .required("Campo obrigatório"),
});
