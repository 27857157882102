import React from "react";
import { BrowserRouter } from "react-router-dom";

import GlobalStyles from "styles/global";
import Routes from "routes";
import { useModal } from "hooks/modal";
import { Modal } from "./components/Modal";

const App: React.FC = () => {
  const { isOpen } = useModal();

  return (
    <>
      <BrowserRouter>
        {isOpen && <Modal />}
        <Routes />
      </BrowserRouter>
      <GlobalStyles />
    </>
  );
};

export default App;
