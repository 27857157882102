export enum TiposSituacoes {
  iniciado = 10,
  emAndamento = 20,
  concluido = 30,
  cancelado = 40,
  divergencia = 50,
}

export enum TiposBancos {
  c6Bank = 5,
}
