import { FormHandles } from "@unform/core";
import {
  useState,
  useCallback,
  useRef,
  useContext,
  createContext,
} from "react";

type FormContextData = {
  setFormData(formData: {}): void;
  data: any;
  formRef: React.RefObject<FormHandles>;
  resetFormData(): void;
};

const FormContext = createContext<FormContextData>({} as FormContextData);

const FormProvider: React.FC = ({ children }) => {
  const [data, setData] = useState({});
  const formRef = useRef<FormHandles>(null);

  const setFormData = useCallback(
    (formData: {}) => {
      if (formData) {
        const newData = { ...data, ...formData };
        setData(newData);
      }
    },
    [data]
  );

  const resetFormData = useCallback(() => {
    setData({});
  }, []);

  return (
    <FormContext.Provider value={{ setFormData, data, formRef, resetFormData }}>
      {children}
    </FormContext.Provider>
  );
};

function useForm(): FormContextData {
  const context = useContext(FormContext);

  if (!context) {
    throw new Error("useForm must be used within an FormProvider");
  }

  return context;
}

export { FormProvider, useForm };
