import {
  InputHTMLAttributes,
  useEffect,
  useRef,
  useState,
  useCallback,
  RefObject,
} from "react";

import { FormHandles, useField } from "@unform/core";
import { isCnpj, isCpf } from "validator-brazil";
import { Container, Error } from "./styles";

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  icon?: string;
  disabled?: boolean;
  iconPayment?: boolean;
  mask?: string;
  number?: boolean;
  cpfCnpj?: boolean;
  formRef?: RefObject<FormHandles>;
}

export const FormInput: React.FC<InputProps> = ({
  icon,
  label,
  mask = "",
  number = false,
  disabled,
  children,
  cpfCnpj = false,
  formRef,
  ...rest
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [isFocused, setIsFocused] = useState(false);
  const { fieldName, defaultValue, error, registerField, clearError } =
    useField(label);

  const maxLength = mask ? mask.length : undefined;

  const handleInputBlur = useCallback(() => {
    setIsFocused(false);
  }, []);

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
    clearError();
  }, [clearError]);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef,
      getValue: (ref) => {
        return ref.current.value;
      },
      setValue: (ref, value) => {
        ref.current.value = value;
      },
      clearValue: (ref) => {
        ref.current.value = "";
      },
    });
  }, [fieldName, formRef, registerField]);

  const handleApplyMask = useCallback(
    (value: string) => {
      if (mask) {
        const splitedMask = mask.split("");
        const splitedValue = value.split("");

        const maskedValue = splitedValue.map((digit, index) => {
          const maskDigit = splitedMask[index];
          if (digit === maskDigit) {
            return digit;
          }

          if (number) {
            if (!digit.match(/^[0-9]$/)) {
              return "";
            }
          }

          if (maskDigit === "#" && !number) {
            return digit;
          }
          if (maskDigit === "9") {
            return digit.match(/^[0-9]$/) ? digit : "";
          }

          if (mask.length === index + 1) {
            return maskDigit;
          }
          return maskDigit + digit;
        });

        if (inputRef.current) {
          inputRef.current.value = maskedValue.join("");
        }
      } else if (inputRef.current) {
        inputRef.current.value = value;
      }
    },
    [mask, number]
  );

  const handleVerifyCpfCnpj = useCallback(
    (value: string) => {
      let verifyDoc = false;
      const formartCpfCnpj = value
        .replaceAll(".", "")
        .replace("/", "")
        .replace("-", "");
      clearError();

      if (value.length <= 14) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        mask = "999.999.999-99";
        handleApplyMask(value);

        if (formartCpfCnpj.length === 11) {
          verifyDoc = isCpf(value);

          if (!verifyDoc && formRef) {
            formRef.current?.setFieldError(label, "CPF inválido");
          }
        }
      } else {
        mask = "99.999.999/9999-99";
        handleApplyMask(value);

        if (formartCpfCnpj.length === 14) {
          verifyDoc = isCnpj(value);

          if (!verifyDoc && formRef) {
            formRef.current?.setFieldError(label, "CNPJ inválido");
          }
        }
      }
    },
    [clearError, formRef, label, handleApplyMask]
  );

  return (
    <>
      <Container isFilled={!!error} isFocused={isFocused} disabled={disabled}>
        {icon && <img src={icon} alt="iconInput" />}

        <div className={`${error && "is-invalid"}`} id="contentInput">
          <input
            className="form-control"
            maxLength={maxLength}
            onFocus={handleInputFocus}
            onBlur={handleInputBlur}
            onChangeCapture={(e) =>
              cpfCnpj
                ? handleVerifyCpfCnpj(e.currentTarget.value)
                : handleApplyMask(e.currentTarget.value)
            }
            defaultValue={defaultValue}
            ref={inputRef}
            data-cy={label}
            disabled={disabled}
            {...rest}
          />
        </div>

        {children && <div className="logo">{children}</div>}
      </Container>
      <Error error={error}>
        {error && <span>{error ?? "Erro desconhecido"}</span>}
      </Error>
    </>
  );
};
