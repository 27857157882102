// eslint-disable-next-line no-shadow
export enum dataAddressTypes {
  SET_USER_ADDRESS = "@user/SET_USER_ADDRESS",
}

export interface dataAddressProps {
  clienteTelefone: string;
  clienteEmail: string;
  souTitular: boolean;
  documentoTitularCartao?: string;
  telefoneTitularCartao?: string;
  emailTitularCartao?: string;
  cep: string;
  cidade: string;
  logradouro: string;
  numero: string;
  bairro: string;
  complemento?: string;
  uf: string;
}

export interface userDataAddressProps {
  data: dataAddressProps;
}

export const schemaAddressProps = {
  clienteTelefone: "",
  clienteEmail: "",
  souTitular: false,
  documentoTitularCartao: "",
  telefoneTitularCartao: "",
  emailTitularCartao: "",
  cep: "",
  cidade: "",
  logradouro: "",
  numero: "",
  bairro: "",
  complemento: "",
  uf: "",
};
