import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  justify-content: space-between;

  color: #334156;
  background: #ffda6c;
  border-radius: 8px;

  align-items: center;
  padding: 14px;
  width: 100%;

  div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  transition: all 0.2s;
`;

export const TextSmall = styled.p`
  font-size: 13px;
  color: #334156;
  @media screen and (max-width: 530px) {
    font-size: 10px;
  }
`;

export const TextBold = styled.p`
  font-size: 14px;
  color: #334156;
  font-weight: bold;
  @media screen and (max-width: 530px) {
    font-size: 13px;
  }
`;
