import { object, string } from "yup";

export default object().shape({
  numeroCartao: string()
    .min(17, "Mínimo 14 caracteres")
    .max(20, "Maximo 16 caracteres"),
  cvv: string()
    .matches(/^[0-9]+$/, "Apenas números")
    .min(3, "Mínimo 3 caracteres"),
  nomeCartao: string().required("Campo Obrigatório"),
  validade: string()
    .required("Campo Obrigatório")
    .min(7, "Mínimo 6 caracteres"),
});
